import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./Hero.scss";

function Hero() {
	const [heroHeight, setHeroHeight] = useState("90vh");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		const navbarHeight = document.querySelector(".navbar").offsetHeight;
		setHeroHeight(`calc(100vh - ${navbarHeight}px)`);
	}, []);

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setSuccess(false);
		const url =
			"https://script.google.com/macros/s/AKfycby1cT60blv3N_aVabyvTjUfv2W3IP3_PTof0d207LbrNnmWn2qcf8zS9w4LEcdd9Xz32g/exec";
		const formBody = new URLSearchParams({ email: email }).toString();

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: formBody,
			});

			if (response.ok) {
				console.log("Email submitted successfully");
				setSuccess(true);
			} else {
				console.error("Failed to submit email");
			}
		} catch (error) {
			console.error("Error submitting email:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container
			id="hero"
			className="hero-container"
			style={{ height: heroHeight }}
		>
			<Row className="text-center">
				<h1 className="mb-4 text-white">
					Unlock the <span className="text-secondary">full</span> potential of
					your startup
				</h1>
				<p className="lead text-gray-3 mb-5">
					We love small businesses, innovative thinkers, and founders. We can
					provide your business with the tools to thrive in today’s market, from
					development to design and branding. Fill out your email to start a
					conversation.
				</p>

				<Col md={6} className="text-center w-100 mb-4 mb-md-10">
					<Form
						className="d-flex flex-column flex-md-row justify-content-center gap-2"
						onSubmit={handleSubmit}
					>
						<Col md={5}>
							<Form.Group className="form-group">
								<Form.Control
									className="input text-white"
									type="email"
									placeholder="Enter your email"
									name="email"
									value={email}
									onChange={handleEmailChange}
									required
								/>
							</Form.Group>
						</Col>
						<Button
							variant="secondary"
							type="submit"
							disabled={loading || success}
						>
							{loading ? (
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
							) : success ? (
								<FontAwesomeIcon icon={faCheck} />
							) : (
								"Get Quote"
							)}
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
}

export default Hero;
