import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./Packages.scss";

function Packages() {
	return (
		<Container id="packages" className="py-10 my-10">
			<h2 className="text-center text-white mb-4">Packages</h2>

			<p className="lead text-center text-gray-3 mb-5">
				All packages can be upgraded, combined, and tailored. Contact us for a
				free and non-binding conversation
			</p>

			<Row className="justify-content-center">
				<Col md={4} className="mb-4">
					<Card className="border-gray-2 p-1" bg="background">
						<Card.Body>
							<Card.Title className="text-gray-4">
								Business Branding Package
							</Card.Title>

							<Card.Text as="h2" className="text-white">
								$1,200
							</Card.Text>

							<ul className="list-unstyled packages py-1">
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Logo Design
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Business Card Design
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Brand Guidelines Document
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Social Media Kit
								</li>
							</ul>

							<Link to="/contact">
								<Button variant="outline-secondary">Get started</Button>
							</Link>
						</Card.Body>
					</Card>
				</Col>

				<Col md={4} className="mb-4">
					<Card className="border-secondary p-1" bg="background">
						<Card.Body>
							<Card.Title className="text-gray-4">
								Full Website Package
							</Card.Title>

							<Card.Text as="h2" className="text-white">
								$2,000
							</Card.Text>

							<ul className="list-unstyled packages py-1">
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Custom Website Design
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									React.js Development
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									SEO Optimization
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Contact Form Setup
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Additional services (request)
								</li>
							</ul>

							<Link to="/contact">
								<Button variant="secondary">Get started</Button>
							</Link>
						</Card.Body>
					</Card>
				</Col>

				<Col md={4} className="mb-4">
					<Card className="border-gray-2 p-1" bg="background">
						<Card.Body>
							<Card.Title className="text-gray-4">
								Marketing Essentials Package
							</Card.Title>

							<Card.Text as="h2" className="text-white">
								$750
							</Card.Text>

							<ul className="list-unstyled packages py-1">
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Social Media Setup
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Email Marketing Setup
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Google Analytics Integration
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Monthly Usage and Performance Report
								</li>
							</ul>

							<Link to="/contact">
								<Button variant="outline-secondary">Get started</Button>
							</Link>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Packages;
